import React from "react";

import ScreenComponent from "./ScreenComponent";
import { connect } from "react-redux";

class ProjectScreen extends React.Component {
  getUrlParameters() {
    let searchParams = new URLSearchParams(window.location.search);
    let urlParameters = [];
    searchParams.forEach((value, key) => {
      urlParameters.push({
        uid: Math.random(),
        urlParamName: key,
        value,
        sourceType: "urlParam",
      });
    });
    return urlParameters;
  }

  render() {
    const propsToPass = {
      key: this.props.projectUpdated,
      route: this.props.route,
      screenName: this.props.screen.name,
      passedParameters: [
        ...this.getUrlParameters(),
        ...(this.props.passedParameters || []),
      ],
    };

    return <ScreenComponent {...propsToPass} />;
  }
}

export default connect((state) => ({
  projectUpdated: state.pState.APP?.projectUpdated,
}))(ProjectScreen);
