import React from "react";
import _ from "lodash";

const TextInput = (props) => {
  return (
    <input
      {...{
        onChange: (e) => props.onChangeText?.(e.target.value),
        ..._.omit(props, "onChangeText"),
      }}
    />
  );
};

export default TextInput;
