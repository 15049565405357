import io from "socket.io-client";

import { getSocket, setSocket } from "../../Providers/socket";
import config from "../../Config";
import socketListeners from "./socketListeners";
import { store } from "../../store";
import { deviceId } from "../../Providers/deviceId";

export const initSocket = async ({ onConnect }) => {
  try {
    let existingSocket = getSocket();
    existingSocket && existingSocket.disconnect();
    setSocket(null);

    let query = {};

    const socket = io(config.url.socket_url, {
      query,
      extraHeaders: await getHeaders({}),
    });
    setSocket(socket);

    socket.on("connect", () => {
      console.info("Socket connected:", socket.id);
      if (onConnect) onConnect(socket);
    });

    socket.on("error", (error) => {
      console.warn("Socket Error: ", error);

      if (error === "Unauthorize") {
        // logoutUser();
      }
    });

    socketListeners(socket);

    return socket;
  } catch (e) {
    console.warn(e);
    console.error("Error initiating socket", e.message);
  }
};

const getHeaders = async ({ headers = {} }) => {
  let state = store?.getState();

  return {
    "Content-Type": "application/json",
    "x-device-id": deviceId,
    "x-subdomain": window.location.host.split(".")[0],
    "x-project-id": state.pState.APP?.projectData?._id,
    ...headers,
  };
};
