import React, { useEffect, useState } from "react";
import api from "../../Services/Api/api";
import { store } from "../../store";

import StripePayCheckout from "./StripePayCheckout";

const fetchPublishableKey = async ({ projectId }) => {
  try {
    const { publicKey } = await api
      .get("v1/payment/stripe/public-key", {
        filter: JSON.stringify({
          where: { project: projectId, provider: "stripe" },
        }),
      })

    // return 'pk_test_51JiIrFSDy4FyoZAkUnehXYo2mkAfocvXKdlz5U6b3yYhixDkUydsSBjZ2MXOYi7gqY79LxPdkgzFbnGebsjsaino00TPo296my';
    return publicKey;
  } catch (e) {
    console.warn("Unable to fetch publishable key. Is server running?");
    window.alert("Error: Unable to initialise payment");
    return null;
  }
};

const StripePay = (props) => {
  const [loading, setLoading] = useState(true);
  const [publishableKey, setPublishableKey] = useState(null);
  useEffect(() => {
    const activeProjectId = store?.getState()?.pState?.APP?.projectData?._id;

    fetchPublishableKey({
      projectId: activeProjectId,
    }).then((publishableKey) => {
      setPublishableKey(publishableKey);
      setLoading(false);
    });
  }, []);

  if (loading) return <div>Loading...</div>;
  else
    return (
      <StripePayCheckout
        {...{
          ...props,
          publishableKey,
        }}
      />
    );
};

export default StripePay;
