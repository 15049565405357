import React from 'react';
import MapContainer from './MapContainer';
class RenderMap extends React.Component {
  render() {
    const {
      props: {domNode, activeTab, elementDataStore},
    } = this;
    const operation = elementDataStore?.operations?.[0];
    const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];

    return (
      <>
        <MapContainer
          {...{
            key: operation?.operation,
            data: operation,
            onPress: this.props.onPress,
            style: activeTabStyle,
          }}
        />
      </>
    );
  }
}

export default RenderMap;
