import React from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import PActions from "../../Stores/redux/Persisted/Actions";
import UnpActions from "../../Stores/redux/Unpersisted/Actions";
import RenderDomNode from "./RenderElements/RenderDomNode";

import api from "../../Services/Api/api";
import Dom from "../../appxolo-engine/modules/dom/Dom";
import ScreenDataStore from "../../appxolo-engine/modules/calculation/execution/ScreenDataStore";
import databaseModule from "../../Modules/database";
import utils from "../../Modules/utils/utils";
import ScreenModal from "../ProjectScreen/ScreenModal";
import { setRouter } from "../../Providers/router";
import { setDatastore } from "../../Providers/datastore";

const { DomTree } = Dom;

class ScreenComponentInnerClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = { render: 0, screenModal: null };

    const { screenName, projectData, screenId } = this.props;

    this.screen = projectData.screens?.find((x) =>
      screenId ? x._id === screenId : x.name === screenName
    );
    this.dom = new DomTree(this.screen?.data?.dom, console.warn);

    this.mergeDataStoreTimer = null;
    this.dataStore = new ScreenDataStore({
      dom: this.dom,
      storeData: (data) => {
        clearTimeout(this.mergeDataStoreTimer);
        this.mergeDataStoreTimer = setTimeout(() => {
          // this.props.setScreenState(
          //   {
          //     [this.screen._id]: data,
          //   },
          //   "APP_DATASTORE"
          // );
          this.rerender();
        }, 5);
      },
    });

    setDatastore({ dataStore: this.dataStore, screenId });

    this.utils = utils({
      ...this.props,
      projectData,
      setScreenModal: (x) => this.setState({ screenModal: x }),
    });

    window.appxolo = {
      dataStore: this.dataStore,
    };
    console.log({ dataStore: this.dataStore, screenId: this.screen._id });
  }

  componentDidMount() {
    if (this.props.router) setRouter(this.props.router);

    this.setScreenContainerDataStore({ dataStore: this.dataStore });
  }

  setScreenContainerDataStore(data) {
    if (this.props.dataStore && this.props.domNode?.id && this.props.rowIds) {
      const elementDataStore =
        this.props.dataStore.data[this.props.domNode.id]?.[
          this.props.rowIds.join()
        ];

      this.props.dataStore.mergeData({
        elementId: this.props.domNode.id,
        rowIndices: this.props.rowIndices,
        rowIds: this.props.rowIds,
        containerType: 'screen',
        obj: {
          ...elementDataStore,
          ...data,
          elementId: this.props.domNode.id,
          rowIds: this.props.rowIds,
          rowIndices: this.props.rowIndices,
          updatedAt: Date.now(),
        },
      });
    }
  }

  rerenderTimer = null;
  rerender() {
    clearTimeout(this.rerenderTimer);
    this.rerenderTimer = setTimeout(() => {
      this.setState({ render: Date.now() });
    }, 50);
  }

  render() {
    const rootElement = this.dom.tree;

    const { screenModal } = this.state;

    const commonProps = {
      ...this.props,
      screen: this.screen,
      dom: this.dom,
      dataStore: this.dataStore,
      // storeData: this.mergeDataStore.bind(this),
      // unsubscribeToAllElementChanges:
      //   this.unsubscribeToAllElementChanges.bind(this),
      // subscribeToElemntChanges: this.subscribeToElemntChanges.bind(this),
      utils: this.utils,
      api: api,
      databaseModule,
      RenderDomNode,
      ScreenComponent,
    };

    return (
      <>
        {rootElement?.children?.map((domNode, i) => (
          <RenderDomNode
            {...{
              ...commonProps,
              key: domNode.id,
              domNode,
              indices: [0, i],
              rowIndices: [0],
              rowIds: ["DEFAULT"],
            }}
          />
        ))}

        {screenModal ? (
          <ScreenModal
            {...{
              style: { flex: 1 },
              ...commonProps,
              ...screenModal,
              visible: !!screenModal.screenName,
              setModal: (x) => this.setState({ screenModal: x }),
            }}
          />
        ) : null}
      </>
    );
  }
}

const ScreenComponentInner = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const router = { navigate, params };
  return <ScreenComponentInnerClass {...{ ...props, router }} />;
};

const SCREEN_NAME = "APP";
const mapStateToProps = (state) => ({
  projectData: state.pState[SCREEN_NAME]?.projectData,
});
const mapDispatchToProps = (dispatch) => ({
  setScreenState: (obj, persist = false, screenName = SCREEN_NAME) =>
    persist
      ? dispatch(PActions.setPScreenState(screenName, obj))
      : dispatch(UnpActions.setVScreenState(screenName, obj)),
});

const ScreenComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenComponentInner);

export default ScreenComponent;
