// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment with the PaymentRequestButton using the official Stripe docs.
// https://stripe.com/docs/stripe-js/elements/payment-request-button#react

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentRequestButtonElement,
  Elements,
  useStripe,
  CardElement,
  useElements,
} from "@stripe/react-stripe-js";
import api from "../../Services/Api/api";

const Result = ({ children }) => <div className="result">{children}</div>;

const ErrorResult = ({ children }) => <div className="error">{children}</div>;

const NotAvailableResult = () => (
  <Result>
    <p style={{ textAlign: "center" }}>
      PaymentRequest is not available in your browser.
    </p>
    {window.location.protocol !== "https:" && (
      <p style={{ textAlign: "center" }}>
        Try using{" "}
        <a href="https://ngrok.com" target="_blank" rel="noopener noreferrer">
          ngrok
        </a>{" "}
        to view this demo over https.
      </p>
    )}
  </Result>
);

const ELEMENT_OPTIONS = {
  style: {
    paymentRequestButton: {
      type: "buy",
      theme: "dark",
    },
  },
};

const CheckoutFormOnetime = (props) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notAvailable, setNotAvailable] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [stripeLoaded, setStripeLoaded] = useState(false);

  useEffect(() => {
    if (!stripe || stripeLoaded) {
      // We can't create a PaymentRequest until Stripe.js loads.
      return;
    }

    try {
      setStripeLoaded(true);

      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total Amount",
          amount: isNaN(props.price) ? props.price : parseFloat(props.price),
        },
      });

      pr.on("paymentmethod", async (event) => {
        setPaymentMethod(event.paymentMethod);
        event.complete("success");
        props.callback?.(null, { paymentMethod: event.paymentMethod });
      });

      pr.canMakePayment().then((canMakePaymentRes) => {
        if (canMakePaymentRes) {
          setPaymentRequest(pr);
        } else {
          setNotAvailable(true);
        }
      });
    } catch (e) {
      props.callback?.(e);
    }
  }, [stripe]);

  return (
    <form>
      {paymentRequest && (
        <PaymentRequestButtonElement
          onClick={(event) => {
            if (paymentMethod) {
              event.preventDefault();
              setErrorMessage(
                "You can only use the PaymentRequest button once. Refresh the page to start over."
              );
            }
          }}
          options={{
            ...ELEMENT_OPTIONS,
            paymentRequest,
          }}
        />
      )}
      {notAvailable && <NotAvailableResult />}
      {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
      {paymentMethod && <Result>Got PaymentMethod: {paymentMethod.id}</Result>}
    </form>
  );
};

const CheckoutFormRecurring = (props) => {
  const [loading, setLoading] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const createSubscription = async (paymentMethodId) => {
    const payload = {
      data: { paymentMethodId, price: props.price },
      project: props.projectData._id,
    };

    const { subscription } = await api.post(
      "v1/payment/stripe/create-subscription",
      payload
    );

    console.log({ payload, subscription });

    return subscription;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create Payment Method
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.error(error);
    } else {
      setLoading("subscription");
      try {
        const subscription = await createSubscription(paymentMethod.id);
        if (!subscription) {
          throw new Error("Something went wrong");
        } else {
          console.log("Subscription successful:", subscription);
          props.callback?.(null, subscription);
        }
      } catch (error) {
        props.callback?.(error);
      }
      setLoading(null);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button type="submit" disabled={!!loading}>
        {loading ? "Subscribing" : "Subscribe"}
      </button>
    </form>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
let stripePromise;

const StripePayCheckout = (props) => {
  const [ts, setTs] = useState(0);

  useEffect(() => {
    stripePromise = loadStripe(props.publishableKey);
    setTs(Date.now);
  }, []);

  if (!stripePromise) return null;

  return (
    <Elements stripe={stripePromise}>
      {props.paymentType !== "recurring" ? (
        <CheckoutFormOnetime {...props} />
      ) : (
        <CheckoutFormRecurring {...props} />
      )}
    </Elements>
  );
};

export default StripePayCheckout;
