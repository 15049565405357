import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
} from "chart.js";
import { Line, Bar, Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

function ChartMedia(props) {
  const { elementDataStore } = props;

  if (elementDataStore?.chartType === "line") {
    return <LineChart {...props} />;
  } else if (elementDataStore?.chartType === "bar") {
    return <BarChart {...props} />;
  } else if (elementDataStore?.chartType === "pie") {
    return <PieChart {...props} />;
  } else {
    return null;
  }
}

function LineChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => ({
      labels: "",
      data: dataset?.chartData?.value?.length ? dataset?.chartData?.value : [0],
      borderColor: dataset?.borderColor?.value?.[0] || "rgb(0, 0, 255)",
    })),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Line options={options} data={linedata} />;
    </div>
  );
}

function BarChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => ({
      labels: "",
      data: dataset?.chartData?.value?.length ? dataset?.chartData?.value : [0],
      backgroundColor: dataset?.backgroundColor?.value || ["rgb(0, 0, 255)"],
    })),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Bar options={options} data={linedata} />;
    </div>
  );
}

function PieChart(props) {
  const { domNode, activeTab, elementDataStore } = props;
  const activeTabStyle = activeTab?.styleData?.[domNode?.value?.elementType];
  const data = elementDataStore;

  const linedata = {
    labels: data?.labels?.value || [],
    datasets: data?.datasets?.map((dataset) => {
      const backgroundColor = dataset.backgroundColor?.value?.length
        ? dataset.backgroundColor?.value
        : chartColors;

      return {
        labels: "",
        data: dataset?.chartData?.value?.length
          ? dataset?.chartData?.value
          : [0],
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
      };
    }),
  };

  const style = Object.assign(
    {},
    { width: "100%", height: "500px" },
    activeTabStyle
  );

  return (
    <div style={style}>
      <Pie options={options} data={linedata} />;
    </div>
  );
}

const chartColors = [];
const getRandomHexColor = () => {
  const randomNumber = Math.floor(Math.random() * 16777215);
  const hexColor = `#${randomNumber.toString(16).padStart(6, "0")}`;
  return hexColor;
};

for (let i = 0; i < 100; i++) {
  chartColors.push(getRandomHexColor());
}

export default ChartMedia;
