const operators = {
  multiply: {
    label: 'Multiplied By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 7,
    operate: (x, y) => x * y,
  },
  division: {
    label: 'Divided By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 7,
    operate: (x, y) => x / y,
  },
  plus: {
    label: 'Added To',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 6,
    operate: (x, y) => x + y,
  },
  minus: {
    label: 'Subtracted By',
    inputTypes: ['number'],
    outputType: 'number',
    precedence: 6,
    operate: (x, y) => x - y,
  },
  concat: {
    label: 'Concat With',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => (x?.toString?.() || '') + (y?.toString?.() || ''),
  },
  addCommaItem: {
    label: 'Add Comma Item',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => (x?.toString?.() || '') + (x && y? (', '+y?.toString?.() || ''): y?.toString?.()||""),
  },
  removeCommaItem: {
    label: 'Remove Comma Item',
    inputTypes: ['string', 'number'],
    outputType: 'string',
    precedence: 6,
    operate: (x, y) => {
      let list = x
        ?.toString()
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x !== y?.toString()?.trim());

        return list?.join(", ") || "";
    },
  },
  greaterOrEqual: {
    label: 'Is Greater Then Or Equal To',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x >= y,
  },
  lessOrEqual: {
    label: 'Is Less Than Or Equal To',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x <= y,
  },
  greaterThan: {
    label: 'Is Greater Than',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x > y,
  },
  lessThan: {
    label: 'Is Less Than',
    inputTypes: ['number'],
    outputType: 'boolean',
    precedence: 5,
    operate: (x, y) => x < y,
  },
  equal: {
    label: 'Equals',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 4,
    operate: (x, y) => x === y,
  },
  notEqual: {
    label: 'Does Not Equals',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 4,
    operate: (x, y) => x !== y,
  },
  OR: {
    label: 'Or',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 3,
    operate: (x, y) => !!(x || y),
  },
  AND: {
    label: 'And',
    inputTypes: ['any'],
    outputType: 'boolean',
    precedence: 2,
    operate: (x, y) => !!(x && y),
  },
  IN: {
    label: 'IN',
    inputTypes: ['any'],
    outputType: 'null',
    precedence: 8,
    operate: (x, y, options) => 0, // @TODO
  },
};

const operatorList = Object.keys(operators).map(x => ({
  ...operators[x],
  value: x,
}));

export default {operators, operatorList};
