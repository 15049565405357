import React from "react";

import RenderInputBase from "./RenderInputBase";
import TextInput from "../../../../Components/etc/TextInput";

class RenderTextInput extends RenderInputBase {
  constructor(props) {
    super(props);
  }

  keyPressTriggerTimer = null;
  handleKeyPress(value) {
    clearTimeout(this.keyPressTriggerTimer);
    this.keyPressTriggerTimer = setTimeout(() => {
      this.props.onPress({
        ...this.props,
        data: { value: value },
        triggerOn: "onKeyPress",
      });
    }, 400);
  }

  catchOnChangeValue(x) {
    this.handleKeyPress(x);
    this.onChangeValue(x);
  }

  renderInput(props = {}) {
    return (
      <TextInput
        {...{
          autoCapitalize: "none",
          autoCorrect: "none",
          style: this.styles?.input,
          placeholder: "Type here",
          ...props,
          type: this.props.activeTab?.inputData?.dataValidation || "text",
          value: this.toString(this.value),
          onChangeText: (x) => this.catchOnChangeValue(x),
          onClick: () =>
            this.props.onPress({
              ...this.props,
              triggerOn: "onPress",
            }),
        }}
      />
    );
  }

  renderInputTypes({ inputProps = {}, labelProps = {} }) {
    switch (this.props.activeTab?.inputType?.inputType) {
      case "withBorderAndLabel":
        return (
          <>
            {this.renderLabel(labelProps)}
            {this.renderInput({
              style: {
                ...this.withBorderStyle,
                ...this.styles.input,
              },
              ...inputProps,
            })}
          </>
        );

      case "withLabel":
        return (
          <>
            {this.renderLabel(labelProps)}
            {this.renderInput({
              style: {
                ...this.withoutBorderStyle,
                ...this.styles.input,
              },
              ...inputProps,
            })}
          </>
        );
      case "default":
      default:
        return this.renderInput({
          ...inputProps,
        });
    }
  }

  render() {
    return this.renderInputTypes({});
  }
}

export default RenderTextInput;
