import CryptoJS from "crypto-js";
import forge from "node-forge";

export class Encryption {
  constructor(props) {
    this.api = props.api;
    this.config = props.config;
  }

  publicKey = null;
  publicKeyPromise = null;

  decrypt(encryptedHex, ivHex, secret) {
    // Convert hex strings to WordArrays
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);
    const iv = CryptoJS.enc.Hex.parse(ivHex);

    // Derive the key from the secret using SHA-256
    const key = CryptoJS.SHA256(secret);

    // Create the decryption configuration
    const decrypted = CryptoJS.AES.decrypt({ ciphertext: encrypted }, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted bytes to a UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  async getPublicKey() {
    const { publicKey } = await this.api.get("v1/app/sign");
    this.publicKey = publicKey;
  }

  rsaEncryptData1 = (data, publicKeyPem) => {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publicKey.encrypt(data, "RSA-OAEP");
    return forge.util.encode64(encrypted);
  };

  rsaEncryptData = (plainText, publicKeyPem) => {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publicKey.encrypt(plainText, "RSA-OAEP");
    return forge.util.encode64(encrypted); // Encode to base64
  };

  rsaEncryptData = (plainText, publicKeyPem) => {
    // Generate a random AES key
    const aesKey = forge.random.getBytesSync(16); // 128-bit key

    // Encrypt data with AES
    const cipher = forge.cipher.createCipher("AES-CBC", aesKey);
    const iv = forge.random.getBytesSync(16); // AES block size is 16 bytes
    cipher.start({ iv: iv });
    cipher.update(forge.util.createBuffer(plainText, "utf8"));
    cipher.finish();
    const encryptedData = cipher.output.toHex();

    // Encrypt the AES key with RSA
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encryptedAesKey = publicKey.encrypt(aesKey, "RSA-OAEP");

    // Encode AES key and encrypted data
    return {
      k: forge.util.encode64(encryptedAesKey),
      d: encryptedData,
      i: forge.util.encode64(iv),
    };
  };

  async encrypt(json) {
    if (!json) return json;
    if (this.publicKeyPromise) await this.publicKeyPromise.catch(console.warn);

    if (!this.publicKey) {
      this.publicKeyPromise = this.getPublicKey();
      await this.publicKeyPromise;
    }

    return this.rsaEncryptData(JSON.stringify(json), this.publicKey);
  }
}